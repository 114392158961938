<template>
  <v-container fluid>
    <v-card flat outlined class="pa-4 pa-sm-6">
      <v-sheet
        class="mb-5 text-h6 d-flex justify-space-between align-center"
        color="transparent"
      >
        Project Information
      </v-sheet>
      <v-row class="ms-md-12" v-if="loading">
        <v-col cols="12" sm="6" md="4" v-for="i in 3" :key="i">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-sheet v-else-if="project" color="transparent">
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-briefcase-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ project.name }}</v-list-item-title>
                <v-list-item-subtitle>Project Title</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-note-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ project.code }}
                </v-list-item-title>
                <v-list-item-subtitle>Project Code</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-account-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    project.staff
                      ? project.staff.first_name + " " + project.staff.last_name
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>Coordinator</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12">
            <v-divider class="mb-5"></v-divider>
            <v-sheet class="mb-4" color="transparent">
              <v-sheet class="text-caption" color="transparent"
                >Description</v-sheet
              >
              <v-skeleton-loader
                type="paragraph"
                v-if="loading"
              ></v-skeleton-loader>
              <p class="text--primary" v-else>
                {{ project.description }}
              </p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>

    <v-row v-if="
        $_checkPermission(
          this.auth.permissions,
          'Manage Project Staff',
          'Read'
        ) ||
        $_checkPermission(
          this.auth.permissions,
          'Manage Project Ledgers',
          'Read'
        )
      ">
      <v-col cols="12" md="6" v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Project Ledgers',
            'Read'
          )
        ">
        <v-card flat outlined class="mt-7 wraperx" v-if="getLedgersLoading">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-card>
        <v-card flat outlined class="mt-7 wraperx" v-else>
          <v-card-title class="mb-0 pb-0">
            Project Ledgers
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              small
              @click="newLedgerDialog = true"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Project Ledgers',
                  'Create'
                )
              "
            >
              <v-icon left> mdi-plus </v-icon> New
            </v-btn>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              filled
              dense
              outlined
              v-model="ledgerSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="ledgersHeaders"
            :items="ledgers"
            :search="ledgerSearch"
            :items-per-page="5"
          >
            <template
              v-slot:item.actions="{ item }"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Project Ledgers',
                  'Delete'
                )
              "
            >
              <v-btn
                depressed
                small
                icon
                color="error"
                class="mx-0 px-0 mt-1"
                dark
                @click="
                  selectedLedger = item;
                  deleteLedgerDialog = true;
                "
              >
                <v-icon small> mdi-trash-can-outline </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Project Staff',
            'Read'
          )
        ">
        <v-card flat outlined class="mt-7 wraperx" v-if="getProjectStaffLoading">
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </v-card>
        <v-card flat outlined class="mt-7 wraperx" v-else>
          <v-card-title class="mb-0 pb-0">
            Project Staff
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              depressed
              small
              @click="newStaffDialog = true"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Project Staff',
                  'Create'
                )
              "
            >
              <v-icon left> mdi-plus </v-icon> New
            </v-btn>
          </v-card-title>
          <v-card-title class="primary--text">
            <v-text-field
              filled
              dense
              outlined
              v-model="projectStaffSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            mobile-breakpoint="0"
            :headers="projectStaffHeaders"
            :items="projectStaff"
            :search="projectStaffSearch"
            :items-per-page="5"
          >
            <template v-slot:item.staff.first_name="{ item }">
              {{
                item.staff
                  ? item.staff.first_name + " " + item.staff.last_name
                  : "Not assign"
              }}
            </template>
            <template
              v-slot:item.actions="{ item }"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Project Staff',
                  'Delete'
                )
              "
            >
              <v-btn
                depressed
                small
                icon
                color="error"
                class="mx-0 px-0 mt-1"
                dark
                @click="
                  selectedStaff = item;
                  deleteStaffDialog = true;
                "
              >
                <v-icon small> mdi-trash-can-outline </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        $_checkPermission(auth.permissions, 'Manage Projects', 'Delete') ||
        $_checkPermission(auth.permissions, 'Manage Projects', 'Update')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent">
        Administrator Actions
      </v-sheet>
      <v-row v-if="loading">
        <v-col cols="12" v-for="i in 2" :key="i + 14">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list color="actionList" v-else>
        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Projects', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Edit Project</v-list-item-title>
            <v-list-item-subtitle>
              Edit project information in the IMSO database.
              <span class="d-none d-md-inline">
                This action will not maintain a history of changes, making it
                suitable for correcting errors or updating details when tracking
                changes is not necessary.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn depressed color="primary" outlined @click="editItem()">
              <v-icon left> mdi-pencil </v-icon>
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Projects', 'Delete')
          "
        >
          <v-list-item-content>
            <v-list-item-title> Delete Project</v-list-item-title>
            <v-list-item-subtitle>
              Delete project information from the app.
              <span class="d-none d-md-inline">
                This action cannot be undone through the app, and only a
                database admin can restore it. All associated data will be
                hidden from the app.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deleteProjectDialog = true"
            >
              <v-icon left>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- New Ledger dialog -->
    <v-dialog v-model="newLedgerDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> New Projcet Ledger </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newLedgerForm">
            <v-text-field
              flat
              outlined
              label="Ledger Title"
              v-model="newLedger.name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="Ledger Code"
              v-model="newLedger.code"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newLedgerDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="addProjectLedgerX"
            :loading="newLedgerLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Staff dialog -->
    <v-dialog v-model="newStaffDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Add Staff to the Project </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="newStaffForm">
            <v-autocomplete
              flat
              v-model="newStaff.staff_uuid"
              :items="imsoStaff"
              item-text="name"
              item-value="uuid"
              label="Staff *"
              outlined
              :rules="[rules.required]"
            ></v-autocomplete>

            <v-autocomplete
              flat
              v-model="newStaff.title"
              :rules="[rules.required]"
              :items="staffTitles"
              label="Title *"
              outlined
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="newStaffDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="addProjectStaffX"
            :loading="newStaffLoading"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete project dialog -->
    <v-dialog v-model="deleteProjectDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this project? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteProject()"
            :loading="deleteProjectLoading"
          >
            Delete Project
          </v-btn>
          <v-btn color="primary" text @click="deleteProjectDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete project ledger dialog -->
    <v-dialog
      v-model="deleteLedgerDialog"
      persistent
      max-width="290"
      v-if="selectedLedger"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this project ledger? This action
          cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteProjectLedgerX(selectedLedger.uuid)"
            :loading="deleteLedgerLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteLedgerDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete project staff dialog -->
    <v-dialog
      v-model="deleteStaffDialog"
      persistent
      max-width="290"
      v-if="selectedStaff"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to remove this staff from the project? This
          action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteProjectStaffX(selectedStaff.uuid)"
            :loading="deleteStaffLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteStaffDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import {
  getProject,
  deleteProject,
  syncOfflineProjectsChanges,
} from "@/services/projectsService";

import {
  fetchProjectLedgers,
  addProjectLedger,
  deleteProjectLedger,
} from "@/services/projectLedgersService";

import {
  fetchProjectStaff,
  addProjectStaff,
  deleteProjectStaff,
} from "@/services/projectStaffService";

import { fetchAndStoreStaffData } from "@/services/preloadDataService";

import constants from "@/constants.json";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    rules: { required: (value) => !!value || "Required." },
    loading: false,
    dataLoading: false,
    deleteProjectLoading: false,
    deleteProjectDialog: false,
    project: {},
    ledgersHeaders: [
      {
        text: "Title",
        align: "start",
        value: "name",
      },
      {
        text: "Code",
        value: "code",
      },
      { text: "", value: "actions", sortable: false },
    ],
    ledgers: [],
    ledgerSearch: "",
    getLedgersLoading: false,

    projectStaffHeaders: [
      {
        text: "Name",
        align: "start",
        value: "staff.first_name",
      },
      { text: "Title", value: "title" },
      { text: "", value: "actions", sortable: false },
    ],
    projectStaff: [],
    projectStaffSearch: "",
    getProjectStaffLoading: false,
    staffTitles: constants.projectStaffTitles,

    newLedgerDialog: false,
    newLedgerLoading: false,
    deleteLedgerDialog: false,
    deleteLedgerLoading: false,
    selectedLedger: null,
    newLedger: {
      uuid: "",
      name: "",
      code: "",
    },

    newStaffDialog: false,
    newStaffLoading: false,
    deleteStaffDialog: false,
    deleteStaffLoading: false,
    selectedStaff: null,
    newStaff: {
      uuid: "",
      staff_uuid: "",
      title: "",
    },

    imsoStaff: [],
  }),
  async created() {
    if (
      this.$_checkPermission(this.auth.permissions, "Manage Projects", "Read")
    ) {
      this.loading = true;
      await syncOfflineProjectsChanges();
      this.docGetLoading = true;
      this.getLedgersLoading = true;
      this.getProjectStaffLoading = true;
      let getProjectReturn = await getProject(this.$route.params.uuid);
      this.project = getProjectReturn;
      this.loading = false;
      this.dataLoading = true;
      this.imsoStaff = await fetchAndStoreStaffData();
      this.imsoStaff.forEach((staff) => {
        staff["name"] = staff.first_name + " " + staff.last_name;
      });
      this.dataLoading = false;
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Project Ledgers",
          "Read"
        )
      ) {
        this.getLedgers();
      } else {
        this.getLedgersLoading = false;
      }
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Project Staff",
          "Read"
        )
      ) {
        this.getProjectStaff();
      } else {
        this.getProjectStaffLoading = false;
      }
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageProjectList" });
    },
    editItem() {
      this.$router.push({
        name: "PageEditProject",
        params: { uuid: this.project.uuid },
        query: { bc: this.project.name },
      });
    },
    async reFetchProject() {
      this.loading = true;
      await syncOfflineProjectsChanges();
      let getProjectReturn = await getProject(this.$route.params.uuid);
      this.project = getProjectReturn;
      this.loading = false;
    },
    async deleteProject() {
      let data = {
        uuid: this.project.uuid,
      };
      this.deleteProjectLoading = true;
      let deleteProjectReturn = await deleteProject(data);
      this.deleteProjectLoading = false;
      if (deleteProjectReturn == "success") {
        this.deleteProjectDialog = false;
        this.goBack();
      }
    },
    async getLedgers() {
      this.getLedgersLoading = true;
      this.ledgers = await fetchProjectLedgers(this.$route.params.uuid);
      this.getLedgersLoading = false;
    },
    async addProjectLedgerX() {
      if (this.$refs.newLedgerForm.validate()) {
        this.newLedgerLoading = true;
        this.newLedger.uuid = uuidv4();
        let newLedgerReturn = await addProjectLedger(
          this.project.uuid,
          this.newLedger
        );
        this.newLedgerLoading = false;

        if (newLedgerReturn == "success") {
          this.newLedgerDialog = false;

          this.$refs.newLedgerForm.reset();
          this.getLedgers();
        }
      }
    },
    async deleteProjectLedgerX(uuid) {
      this.deleteLedgerLoading = true;
      let deleteLedgerReturn = await deleteProjectLedger(this.project.uuid, {
        uuid: uuid,
      });
      this.deleteLedgerLoading = false;

      if (deleteLedgerReturn == "success") {
        this.deleteLedgerDialog = false;
        this.getLedgers();
      }
    },
    async getProjectStaff() {
      this.getProjectStaffLoading = true;
      this.projectStaff = await fetchProjectStaff(this.$route.params.uuid);
      this.getProjectStaffLoading = false;
    },
    async addProjectStaffX() {
      if (this.$refs.newStaffForm.validate()) {
        this.newStaffLoading = true;
        this.newStaff.uuid = uuidv4();
        let newStaffReturn = await addProjectStaff(
          this.project.uuid,
          this.newStaff
        );
        this.newStaffLoading = false;

        if (newStaffReturn == "success") {
          this.newStaffDialog = false;

          this.$refs.newStaffForm.reset();
          this.getProjectStaff();
        }
      }
    },
    async deleteProjectStaffX(uuid) {
      this.deleteStaffLoading = true;
      let deleteStaffReturn = await deleteProjectStaff(this.project.uuid, {
        uuid: uuid,
      });
      this.deleteStaffLoading = false;

      if (deleteStaffReturn == "success") {
        this.deleteStaffDialog = false;
        this.getProjectStaff();
      }
    },
  },
};
</script>